import { HttpResponse } from "@/types/Http";
import { HttpError } from "@/types/Http";
import { appApiClient } from "../services/apiService";
import axios, { AxiosError } from "axios";
import handleAxiosError from "@/utility/handleError";

export interface MediaResponse {
  error?: string;
  blob: Blob;
}

export interface Blob {
  uniqueKey: string;
  contentType: string;
}

export default class MediaService {
  static async UploadFile(file: File): Promise<HttpResponse> {
    // Check file size before upload.
    if (file.size > 2 * 1024 * 1024) {
      return {
        data: {
          error: "file too big",
        },
      };
    }

    const formData = new FormData();
    formData.append("file", file, file.name);

    try {
      const response = await appApiClient.post("/api/media/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (axios.isAxiosError(response)) {
        if (!response?.response?.data) {
          throw new Error();
        }

        const data = response?.response?.data;

        return { data };
      }

      return {
        data: response.data,
      };
    } catch (error) {
      let handledError: HttpError;
      if (axios.isAxiosError(error)) {
        handledError = handleAxiosError(error);
      } else {
        handledError = {
          code: 500,
          message: "An unknown error occurred",
          data: null,
        };
      }

      return {
        error: handledError,
      };
    }
  }
}
