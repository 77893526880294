import { HttpError } from "@/types/Http";
import { AxiosError, AxiosResponse } from "axios";

export default function handleAxiosError(error: AxiosError): HttpError {
  const response: HttpError = {
    code: error.response?.status ?? 500,
    message: error.response?.statusText ?? "An unknown error occurred",
    data: error.response?.data ?? null,
  };

  return response;
}
