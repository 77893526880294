


















































































import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import Validation from "@/mixins/validation";
import { appApiClient } from "../../services/apiService";
import SnackbarModule from "../../store/snackbarModule";
import CalendarEvent from "@/types/CalendarEvent";
import downSizeImage from "@/utility/downSizeImage";
import MediaService from "@/services/mediaService";

const snackbarModule = getModule(SnackbarModule);

@Component({})
export default class MessageGroupCreateDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Prop({ required: true }) selectedEvent!: CalendarEvent;
  @Ref() private readonly form!: VForm;

  public loading = false;

  public file: File | null = null;

  public async onNewFileAttached(file: File) {
    this.file = file;
  }

  public async onClear(fileKey: string | undefined) {
    this.selectedEvent.fileName = undefined;
  }

  public async onEventUpdate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;

      if (this.file != undefined) {
        if (this.file.type.includes("image")) {
          this.file = await downSizeImage(this.file);
        }

        const fileUploadResponse = await MediaService.UploadFile(this.file);
        this.selectedEvent.fileKey = fileUploadResponse.data.blob.uniqueKey;
        this.selectedEvent.fileName = this.file.name;
        this.file = null;
      }

      const response = await appApiClient.put("api/Calendar/UpdateUserDate", {
        id: this.selectedEvent.id,
        title: this.selectedEvent.title,
        startDate: this.selectedEvent.startDate,
        endDate: this.selectedEvent.endDate,
        content: this.selectedEvent.content,
        isAllDay: true,
        isAdminDate: true,
        fileKey: this.selectedEvent.fileKey,
        fileName: this.selectedEvent.fileName,
      });

      this.$emit("editEvent", response.data.result);

      snackbarModule.setSnackbarMessage("Event updated.");
      this.syncedDialog = false;
    } catch {
      snackbarModule.setSnackbarMessage("Could not update event.");
    } finally {
      this.loading = false;
    }
  }
}
