var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.syncedDialog),callback:function ($$v) {_vm.syncedDialog=$$v},expression:"syncedDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" New Event ")]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onEventCreate($event)}}},[_c('v-card-text',[_c('base-input',{attrs:{"placeholder":"Title","topLabel":"Event Title","rules":[_vm.requiredRule]},model:{value:(_vm.model.title),callback:function ($$v) {_vm.$set(_vm.model, "title", $$v)},expression:"model.title"}}),_c('base-input',{attrs:{"placeholder":"Description","topLabel":"Event Description","rules":[_vm.requiredRule]},model:{value:(_vm.model.content),callback:function ($$v) {_vm.$set(_vm.model, "content", $$v)},expression:"model.content"}}),_c('base-date-picker',{attrs:{"rules":[
            _vm.requiredRule,
            _vm.dateSameOrBefore(
              _vm.model.startDate,
              _vm.model.endDate,
              'Start Date',
              'End Date'
            ) ],"placeholder":"Start Date","topLabel":"Event Start Date"},model:{value:(_vm.model.startDate),callback:function ($$v) {_vm.$set(_vm.model, "startDate", $$v)},expression:"model.startDate"}}),_c('base-date-picker',{attrs:{"rules":[
            _vm.requiredRule,
            _vm.dateSameOrBefore(
              _vm.model.startDate,
              _vm.model.endDate,
              'Start Date',
              'End Date'
            ) ],"placeholder":"End Date","topLabel":"Event End Date"},model:{value:(_vm.model.endDate),callback:function ($$v) {_vm.$set(_vm.model, "endDate", $$v)},expression:"model.endDate"}}),_c('v-file-input',{attrs:{"label":"Event Image"},on:{"change":_vm.onNewFileAttached},model:{value:(_vm.model.fileKey),callback:function ($$v) {_vm.$set(_vm.model, "fileKey", $$v)},expression:"model.fileKey"}})],1),_c('v-card-actions',{staticClass:"dialog-actions"},[_c('v-spacer'),_c('base-secondary-btn',{on:{"click":function($event){_vm.syncedDialog = false}}},[_vm._v(" Cancel ")]),_c('base-primary-btn',{attrs:{"loading":_vm.loading,"type":"submit"}},[_vm._v(" Submit ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }