var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.syncedDialog),callback:function ($$v) {_vm.syncedDialog=$$v},expression:"syncedDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Edit Event "+_vm._s(_vm.selectedEvent.fileName ? _vm.selectedEvent.fileName : "none")+" ")]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onEventUpdate($event)}}},[_c('v-card-text',[_c('base-input',{attrs:{"placeholder":"Title","topLabel":"Event Title","rules":[_vm.requiredRule]},model:{value:(_vm.selectedEvent.title),callback:function ($$v) {_vm.$set(_vm.selectedEvent, "title", $$v)},expression:"selectedEvent.title"}}),_c('base-input',{attrs:{"placeholder":"Description","topLabel":"Event Description","rules":[_vm.requiredRule]},model:{value:(_vm.selectedEvent.content),callback:function ($$v) {_vm.$set(_vm.selectedEvent, "content", $$v)},expression:"selectedEvent.content"}}),_c('base-date-picker',{attrs:{"rules":[
            _vm.requiredRule,
            _vm.dateSameOrBefore(
              _vm.selectedEvent.startDate,
              _vm.selectedEvent.endDate,
              'Start Date',
              'End Date'
            ) ],"placeholder":"Start Date","topLabel":"Event Start Date"},model:{value:(_vm.selectedEvent.startDate),callback:function ($$v) {_vm.$set(_vm.selectedEvent, "startDate", $$v)},expression:"selectedEvent.startDate"}}),_c('base-date-picker',{attrs:{"rules":[
            _vm.requiredRule,
            _vm.dateSameOrBefore(
              _vm.selectedEvent.startDate,
              _vm.selectedEvent.endDate,
              'Start Date',
              'End Date'
            ) ],"placeholder":"End Date","topLabel":"Event End Date"},model:{value:(_vm.selectedEvent.endDate),callback:function ($$v) {_vm.$set(_vm.selectedEvent, "endDate", $$v)},expression:"selectedEvent.endDate"}}),(!_vm.file)?_c('div',[_vm._v(" Image: "+_vm._s(_vm.selectedEvent.fileName ? _vm.selectedEvent.fileName : "No file added")+" ")]):_vm._e(),_c('v-file-input',{attrs:{"placeholder":_vm.selectedEvent.fileName
              ? _vm.selectedEvent.fileName
              : 'Upload Group Image',"dense":""},on:{"change":_vm.onNewFileAttached,"click:clear":function($event){return _vm.onClear(_vm.selectedEvent.fileKey)}},model:{value:(_vm.selectedEvent.fileKey),callback:function ($$v) {_vm.$set(_vm.selectedEvent, "fileKey", $$v)},expression:"selectedEvent.fileKey"}})],1),_c('v-card-actions',{staticClass:"dialog-actions"},[_c('v-spacer'),_c('base-secondary-btn',{on:{"click":function($event){_vm.syncedDialog = false}}},[_vm._v(" Cancel ")]),_c('base-primary-btn',{attrs:{"loading":_vm.loading,"type":"submit"}},[_vm._v(" Update ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }