var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"container-xl"},[_c('base-data-table',{attrs:{"title":'International Dates',"headers":_vm.headers,"items":_vm.items,"noCreate":true,"noSearch":false,"loading":_vm.loading,"sortBy":'startDate',"sortDesc":false},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('base-primary-btn',{staticClass:"ml-2",staticStyle:{"height":"48px"},on:{"click":function($event){_vm.newEventDialog = true}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","min-width":"120"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.onEdit(item)}}},[_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.onDelete(item.id)}}},[_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.startDate))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.endDate))+" ")]}},{key:"item.fileKey",fn:function(ref){
var item = ref.item;
return [(item.fileKey)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.downloadFile(item)}}},[_c('v-icon',[_vm._v("mdi-download-circle-outline")])],1):_vm._e()]}}])})],1),_c('InternationalDateCreateDialog',{attrs:{"dialog":_vm.newEventDialog},on:{"update:dialog":function($event){_vm.newEventDialog=$event},"newEvent":function (item) { return _vm.onNewEvent(item); }}}),_c('InternationalDateUpdateDialog',{attrs:{"dialog":_vm.editEventDialog,"selectedEvent":_vm.selectedEvent},on:{"update:dialog":function($event){_vm.editEventDialog=$event},"editEvent":function (item) { return _vm.onEditEvent(item); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }