



































































import Vue from "vue";
import { appApiClient } from "@/services/apiService";
import SnackbarModule from "../../store/snackbarModule";
import { getModule } from "vuex-module-decorators";
import { DataTableHeader } from "vuetify";
import InternationalDateCreateDialog from "../../components/dialogs/InternationalDateCreateDialog.vue";
import InternationalDateUpdateDialog from "../../components/dialogs/InternationalDateUpdateDialog.vue";
import nameof from "@/utility/nameof";
import CalendarEvent from "@/types/CalendarEvent";

const snackbarModule = getModule(SnackbarModule);

const headers: Array<DataTableHeader<CalendarEvent>> = [
  { text: "", value: "actions", sortable: false },
  { text: "Event Title", value: nameof<CalendarEvent>("title") },
  { text: "Event Description", value: nameof<CalendarEvent>("content") },
  { text: "Event Start Date", value: nameof<CalendarEvent>("startDate") },
  { text: "Event End Date", value: nameof<CalendarEvent>("endDate") },
  { text: "Event Image", value: nameof<CalendarEvent>("fileKey") },
];

interface Data {
  headers: Array<DataTableHeader>;
  items: CalendarEvent[];
  newEventDialog: boolean;
  editEventDialog: boolean;
  selectedEvent?:
    | CalendarEvent
    | {
        title: string;
        content: string;
        startDate: string;
        endDate: string;
        fileName: string;
      };
  loading: boolean;
}

export default Vue.extend({
  components: { InternationalDateCreateDialog, InternationalDateUpdateDialog },
  data(): Data {
    return {
      headers,
      items: [],
      newEventDialog: false,
      editEventDialog: false,
      selectedEvent: {
        title: "",
        content: "",
        startDate: "",
        endDate: "",
        fileName: "",
      },
      loading: false,
    };
  },
  computed: {},
  async mounted() {
    try {
      this.loading = true;
      const response = await appApiClient.get(`api/Calendar/GetAdminDates/`);
      this.items = response.data.events;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to load events.");
    } finally {
      this.loading = false;
    }
  },
  methods: {
    onNewEvent(item: CalendarEvent) {
      this.items.push(item);
    },
    async onDelete(eventId: number) {
      try {
        this.loading = true;
        await appApiClient.delete(`api/Calendar/DeleteUserDate/${eventId}`);
        this.items = this.items.filter((event) => event.id !== eventId);
      } catch {
        snackbarModule.setSnackbarMessage("Failed to delete event.");
      } finally {
        this.loading = false;
      }
    },
    async onEdit(event: CalendarEvent) {
      if (!event.id) {
        return;
      }
      this.selectedEvent = { ...event };
      this.editEventDialog = true;
    },
    async onEditEvent(event: CalendarEvent) {
      this.editEventDialog = false;
      try {
        this.loading = true;
        const response = await appApiClient.get(`api/Calendar/GetAdminDates/`);
        this.items = response.data.events;
      } catch {
        snackbarModule.setSnackbarMessage(
          "Failed to retrieve events after the update."
        );
      } finally {
        this.loading = false;
      }
    },
    async getFile(attachedFileKey: string): Promise<File | undefined> {
      try {
        this.loading = true;

        const response = await appApiClient({
          method: "GET",
          url: `/api/Media/Get/${attachedFileKey}`,
          responseType: "blob",
        });

        if (response.status === 200) {
          const file = new File([response.data], attachedFileKey);
          return file;
        }
      } catch {
        return undefined;
      } finally {
        this.loading = false;
      }
    },
    async downloadFile(item: CalendarEvent) {
      try {
        this.loading = true;

        const fileKey: string = item.fileKey!;

        const file = await this.getFile(fileKey);
        if (!file) return;

        const url = window.URL.createObjectURL(file);
        const anchor = document.createElement("a");
        anchor.style.display = "none";
        anchor.href = url;
        anchor.download = fileKey;
        document.body.appendChild(anchor);
        anchor.click();
        window.URL.revokeObjectURL(url);
      } finally {
        this.loading = false;
      }
    },
  },
});
