import reduce from "image-blob-reduce";

export default async function downSizeImage(file: File): Promise<File> {
  const blob = await reduce().toBlob(file, { max: 450 });

  const newFile = new File([blob], file.name, {
    type: file.type,
  });

  return newFile;
}
