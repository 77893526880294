





































































import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import Validation from "@/mixins/validation";
import { appApiClient } from "../../services/apiService";
import SnackbarModule from "../../store/snackbarModule";
import CalendarEvent from "@/types/CalendarEvent";
import downSizeImage from "@/utility/downSizeImage";
import MediaService from "@/services/mediaService";

const snackbarModule = getModule(SnackbarModule);

@Component({})
export default class MessageGroupCreateDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;

  public model: CalendarEvent = {
    id: 0,
    createdBy: "",
    createdAt: "",
    modifiedBy: "",
    modifiedAt: "",
    isActive: true,
    fileKey: undefined,
    fileName: undefined,
    title: "",
    content: "",
    startDate: "",
    endDate: "",
    isAdminDate: true,
    isAllDay: true,
  };

  public file: File | undefined = undefined;

  public loading = false;

  @Watch("syncedDialog")
  private onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();
  }

  @Watch("model.startDate")
  private onStartDateChange(newStartDate: string) {
    if (!this.model.endDate) {
      this.model.endDate = newStartDate;
    }
  }

  public async onEventCreate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;

      if (this.file != undefined) {
        if (this.file.type.includes("image")) {
          this.file = await downSizeImage(this.file);
        }

        const fileUploadResponse = await MediaService.UploadFile(this.file);
        this.model.fileKey = fileUploadResponse.data.blob.uniqueKey;
        this.model.fileName = this.file.name;
        this.file = undefined;
      }

      const response = await appApiClient.post(
        "api/Calendar/CreateUserDate",
        this.model
      );

      this.$emit("newEvent", response.data.result);

      snackbarModule.setSnackbarMessage("Event created.");
      this.syncedDialog = false;
    } catch {
      snackbarModule.setSnackbarMessage("Could not create event.");
    } finally {
      this.loading = false;
    }
  }

  public async onNewFileAttached(ev: any) {
    this.file = ev;
  }
}
